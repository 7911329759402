import React, { Component } from 'react';
import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import { graphql } from '@apollo/react-hoc';
import withStateMutation from '../withStateMutation';
import { Input } from 'semantic-ui-react';

class HomeNewsletter extends Component {
	state = {
		email: ''
	}

	onEmailChange = (event) => {
		this.setState({
			email: event.target.value
		});
	}

	subscribe = () => {
		const { email } = this.state;
		this.props.mutate({ 
			variables: { email }
		});
		// Clear the email input
		this.setState({ email: '' });
	}

	render() {
		return (
			<section className="home__newsletter">
				<label htmlFor="newsletter" className="label">News Letter</label>
				<div className="home__newsletter__align">
					<Input
						fluid
						type="text"
						className="input--alt input-newsletter"
						placeholder="Email Address"
						id="newsletter"
						value={this.state.email}
						onChange={this.onEmailChange}
					/>
					<button className="btn-alt" onClick={this.subscribe}>Subscribe</button>
				</div>
			</section>
		);
	}
}

const mutation = gql`
	mutation Subscribe($email: String) {
		subscribe(email: $email)
	}
`;

export default compose(
	graphql(mutation),
	withStateMutation()
)(HomeNewsletter);