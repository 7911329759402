/* eslint-disable no-mixed-spaces-and-tabs */
import React from "react";
import Footer from "../components/Footer";
import { Timeline } from "react-twitter-widgets";
import { HomePageText } from "../assets/lang/english";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";

// Assets
import IconInfinity from "../assets/images/home/icon_infinity.svg";
import IconBusiness from "../assets/images/home/icon_business.svg";
import IconPartners from "../assets/images/home/icon_partners.svg";
import IconInfluencers from "../assets/images/home/icon_influencer.svg";
import HomeHeader from "../components/home/HomeHeaderNew";
import HomeNewsletter from "../components/home/HomeNewsletter";
import ReactGA from "react-ga";
import Navbar from "../components/navbar";
import YouTube from "react-youtube";
import styles from "../pages/styles/HomePage.module.scss";

function facebook(d, s, id) {
  var js,
    fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s);
  js.id = id;
  js.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0";
  fjs.parentNode.insertBefore(js, fjs);
}

const HomePage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    facebook(document, "script", "facebook-jssdk");
    ReactGA.pageview("/");
  }, []);

  return (
    <>
      <Navbar home />
      <div className="home">
        <main>
          <div className="home__nav">
            <ul className="home__nav-list" style={{fontSize : "1.7rem"}}>
              <Link to="/">
                <li>Home</li>
              </Link>
              <Link to="/savings">
                <li>Find Businesses</li>
              </Link>
              <Link to="/educators">
                <li>Service Opportunities</li>
              </Link>
              <Link to="/affiliates">
                <li>Plans Sign-Up</li>
              </Link>
              <Link to="/pricing">
                <li>Member Sign-Up</li>
              </Link>
              <Link to="/faq">
                <li>FAQ</li>
              </Link>
            </ul>
          </div>

          <HomeHeader /> 

          <section className="home__section home__section--bus" id="businesses">
            <div className={styles.youtubeDiv}>
              <YouTube
                videoId={"GQ0296g7eSE"}
                opts={{
                  height: "390",
                  width: "640",
                  playerVars: {
                    // https://developers.google.com/youtube/player_parameters
                    autoplay: 0,
                    controls: 1,
                  },
                }}
                containerClassName={styles.youtubeContainer}
              />
            </div>
            <div className="partners__content" data-aos="fade-left">
              <img src={IconBusiness} />
              <h3>Businesses</h3>
              <p>{HomePageText[2]}</p>
              <Link to="/affiliates">
                <button className="btn-text">Learn More</button>
              </Link>
            </div>
          </section>

          <section
            className="home__section home__section--business"
            id="educators"
          >
            {/* <div className='business__img'></div> */}
            <div className="partners__content" data-aos="fade-left">
              <img src={IconPartners} />
              <h3>Educators</h3>
              <p>{HomePageText[3]}</p>
              <Link to="/educators">
                <button className="btn-text">Learn More</button>
              </Link>
            </div>
            <div
              style={{
                padding: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <YouTube
                videoId={"Gzt7t-8W23w"}
                opts={{
                  height: "390",
                  width: "640",
                  playerVars: {
                    // https://developers.google.com/youtube/player_parameters
                    autoplay: 0,
                    controls: 1,
                  },
                }}
                containerClassName={styles.youtubeContainer}
              />
            </div>
          </section>

          <section
            className="home__section home__section--savings"
            id="memberships"
          >
            <div className="savings__img"></div>
            <div className="partners__content" data-aos="fade-left">
              <img src={IconInfinity} />
              <h3>Memberships</h3>
              <p>{HomePageText[1]}</p>
              <Link to="/pricing">
                <button className="btn-text">Learn More</button>
              </Link>
            </div>
          </section>

          <section
            className="home__section home__section--influence"
            id="influencers"
          >
            <div className="business__content" data-aos="fade-right">
              <img src={IconInfluencers} />
              <h3>Influencers</h3>
              <p>{HomePageText[4]}</p>
              <Link to="/educators">
                <button className="btn-text">Learn More</button>
              </Link>
            </div>
            <div className="business__effect"></div>
          </section>
          <section className="social-media" style={{backgroundColor:"transparent"}}>
            <h3>Social Media</h3>
            <div className="social-media__container" style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "1rem",
            }} >
              <div className="social-media__container__api api__buttons" style={{padding : "0 3rem"}}>
                <button
                  className="youtube"
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/watch?v=1bvOcMPJj1M&t="
                    )
                  }
                >
                  <i className="fab fa-youtube"></i>
                </button>
                <button
                  className="linkedin"
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/deluxenetworkprogram/"
                    )
                  }
                >
                  <i className="fab fa-linkedin-in"></i>
                </button>
              </div>
              <div className="social-media__container__api api__facebook">
                <div
                  className="fb-page"
                  data-href="https://www.facebook.com/DNPCorporate/"
                  data-tabs="timeline"
                  data-small-header="true"
                  data-adapt-container-width="true"
                  data-hide-cover="false"
                  data-show-facepile="true"
                >
                  <blockquote
                    cite="https://www.facebook.com/DNPCorporate/"
                    className="fb-xfbml-parse-ignore"
                  >
                    <a href="https://www.facebook.com/DNPCorporate/">
                      Deluxe Network Program
                    </a>
                  </blockquote>
                </div>
              </div>
              <div className="social-media__container__api api__twitter">
                <Timeline
                  dataSource={{
                    sourceType: "profile",
                    screenName: "DNPCorporate",
                  }}
                  options={{
                    username: "TwitterDev",
                    height: "500px",
                  }}
                  onLoad={() => console.log("Timeline is loaded!")}
                />
              </div>
            </div>
          </section>
          <HomeNewsletter />
        </main>
        <Footer />
      </div>
    </>
  );
};

export default HomePage;
